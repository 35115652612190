import React, { useEffect } from 'react';
import './App.css';
import Fish from './Fish';

function App() {
  useEffect(() => {
    document.title = "ASUŞU DG";
  }, []);
  
  return (
    <div className="App">
      <h1>ASUŞUM SENİ
        <br />
        <br />
        
        ÇOK SEVİYORUM
        
        <br />
        <br />
        DOĞUM GÜNÜN
        
        <br />
        <br />
        KUTLU OLSUN
        <br />
        <br />

        <br />
        UYYYi
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        -ENES
      </h1>
      <Fish imageUrl="https://png.pngtree.com/png-clipart/20240227/original/pngtree-fresh-anchovy-in-white-background-isolated-photo-png-image_14427532.png" duration={10} bottom={10} />
      <Fish imageUrl="https://skyfood.com.tr/wp-content/uploads/2019/08/GumusBaligi.png" duration={8} bottom={30} />
      <Fish imageUrl="https://png.pngtree.com/png-clipart/20240227/original/pngtree-fresh-anchovy-in-white-background-isolated-photo-png-image_14427532.png" duration={12} bottom={30} />
      <Fish imageUrl="https://png.pngtree.com/png-clipart/20240227/original/pngtree-fresh-anchovy-in-white-background-isolated-photo-png-image_14427532.png" duration={11} bottom={20} />
      <Fish imageUrl="https://png.pngtree.com/png-clipart/20240227/original/pngtree-fresh-anchovy-in-white-background-isolated-photo-png-image_14427532.png" duration={13} bottom={35} />
      <Fish imageUrl="https://png.pngtree.com/png-clipart/20240227/original/pngtree-fresh-anchovy-in-white-background-isolated-photo-png-image_14427532.png" duration={15} bottom={40} />
      <Fish imageUrl="https://png.pngtree.com/png-clipart/20240227/original/pngtree-fresh-anchovy-in-white-background-isolated-photo-png-image_14427532.png" duration={5} bottom={45} />
      <Fish imageUrl="https://png.pngtree.com/png-clipart/20240227/original/pngtree-fresh-anchovy-in-white-background-isolated-photo-png-image_14427532.png" duration={7} bottom={35} />
      <Fish imageUrl="https://png.pngtree.com/png-clipart/20240227/original/pngtree-fresh-anchovy-in-white-background-isolated-photo-png-image_14427532.png" duration={10} bottom={30} />
      <Fish imageUrl="https://png.pngtree.com/png-clipart/20240227/original/pngtree-fresh-anchovy-in-white-background-isolated-photo-png-image_14427532.png" duration={4} bottom={25} />
      <Fish imageUrl="https://png.pngtree.com/png-clipart/20240227/original/pngtree-fresh-anchovy-in-white-background-isolated-photo-png-image_14427532.png" duration={5} bottom={55} />
      
    </div>
  );

}

export default App;
