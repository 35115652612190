import React from 'react';
import './Fish.css';

const Fish = ({ imageUrl, duration, bottom }) => {
    const fishStyle = {
        animationDuration: `${duration}s`,
        bottom: `${bottom}%`
    };

    return (
        <div className="fish" style={fishStyle}>
            <img src={imageUrl} alt="Swimming Fish" />
        </div>
    );
};

export default Fish;
